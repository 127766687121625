<template>
    <!-- <div> -->
        <van-tabbar v-model="activeVal" :placeholder='true'>
            <van-tabbar-item @click="linkUrl('/homeIndex')">
                <span>首页</span>
                <template #icon="props">
                    <img :src="props.active ? icon.active : icon.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item @click="linkUrl('/meeting')">
                <span>我的会议</span>
                <template #icon="props">
                    <img :src="props.active ? meeting.active : meeting.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item @click="linkUrl('/index')">
                <template>
                    <img class="signicon" src="../assets/tabbar/hy-icon.png" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item @click="linkUrl('/message')">
                <span>消息</span>
                <template #icon="props">
                    <img :src="props.active ? message.active : message.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item @click="linkUrl('/usercenter')">
                <span>我的</span>
                <template #icon="props">
                    <img :src="props.active ? user.active : user.inactive" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    <!-- </div> -->
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
export default {
  name: "TabberItem",
  props: {
    active: Number,
  },
  data() {
    return {
        activeVal: this.active,
        icon: {
            active: require('../assets/tabbar/index-icon.png'),
            inactive: require('../assets/tabbar/index-icon-in.png'),
        },
        meeting:{
            active: require('../assets/tabbar/meeting-icon.png'),
            inactive: require('../assets/tabbar/meeting-icon-in.png'),
        },
        message:{
            active: require('../assets/tabbar/message-icon.png'),
            inactive: require('../assets/tabbar/message-icon-in.png'),
        },
        user:{
            active: require('../assets/tabbar/user-icon.png'),
            inactive: require('../assets/tabbar/user-icon-in.png'),
        }
    }
  },
  
  methods:{
    linkUrl(url){
        this.$router.push({path:url})
    }
  },
   components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
 
  },
};
</script>

<style scoped lang="scss">
    .signicon{
        width: 42px;
        height: 42px;
        font-size: 0;
        vertical-align: middle;
    }
</style>