import axios from 'axios'
import config from '../config'
import router from '@/router'
import Cookies from 'js-cookie'
import { Toast } from 'vant'
console.info(config.serviceHost)
// create an axios instance
// const service = axios.create({
axios.defaults.baseURL = config.serviceHost,
  axios.defaults.withCredentials = true, // send cookies when cross-domain requests
  axios.defaults.timeout = 50000 // request timeout
// })
// application/x-www-form-urlencoded
// request interceptor
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    const token = Cookies.get("tonken");
    // config.headers.clientId = 'SSO_SYS'
    if (token) {
      config.headers['X-Access-Token'] = token
    }
    return config;
  },
  error => {
    return Promise.error(error);
  }
);
const err = (error) => {

  console.info(error)
  if (error.response) {

    let data = error.response.data
   
    switch (error.response.status) {
      case 403:
        Toast("拒绝访问");
        break
      case 500:
        if (data.message.includes("Token失效")) {
          Toast("登录已过期,重新登录");
          Cookies.remove("tonken");
        } else {
          Toast(data.message);
        }
        break
      case 404:
        Toast("很抱歉，资源未找到");
        break
      case 504:
        Toast("网络超时");
        break
      case 401:
        Toast("未授权，请重新登录");
        break
      default:
        Toast(data.message);
        break
    }
  } else if (error.message) {
    if (error.message.includes('timeout')) {
      Toast('网络超时');
    } else {
      Toast(error.message);
    }
  }
  return Promise.reject(error)
};
// response interceptor
axios.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // console.info(res)
    // if the custom code is not 20000, it is judged as an error.
    if (res.code != '0') {
      return res
      // return Promise.reject(new Error(`${res.message}` || '网络错误'))
    } else {
      return res
    }
  },
  // error => {
  //   console.info(error,'ressssssssssssssss-----------')
  //   // const text = common.isEmpty(error.response) ? '网络连接超时' : error.response.data.message
  //   return Promise.reject(error)
  // }
  err
)
// response interceptor

// export default service;
// 封装xiaos请求  封装axios里的get
export function get(url, params = {}, change) {
  return new Promise((resolve, reject) => {
    if (change) {
      axios.get(config.serviceHost1 + '' + url, {
        params: params,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          // console.info(response,'response.data')
          resolve(response);
        })
        .catch(err => {
          reject(err)
        })
    } else {
      axios.get(config.serviceHost + '' + url, {
        params: params,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          // console.info(response,'response.data')
          resolve(response);
        })
        .catch(err => {
          reject(err)
        })
    }
  })
}

// 封装xiaos请求  封装axios里的post
export function post(url, data = {}, change) {
  return new Promise((resolve, reject) => {
    if (change) {
      axios.post(config.serviceHost1 + '' + url, data)
        .then(response => {
          resolve(response);
        }, err => {
          reject(err)
        })
    } else {
      axios.post(config.serviceHost + '' + url, data)
        .then(response => {
          resolve(response);
        }, err => {
          reject(err)
        })
    }
  })
}
// 封装xiaos请求  封装axios里的put
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(config.serviceHost + '' + url, data)
      .then(response => {
        resolve(response);
      }, err => {
        reject(err)
      })
  })
}
