
import store from '../store'
window.Number.prototype.formatMoney = function(places, symbol, thousand, decimal) {
  places = !isNaN(places = Math.abs(places)) ? places : 2
  symbol = symbol !== undefined ? symbol : ''
  thousand = thousand || ','
  decimal = decimal || '.'
  var number = this
  var negative = number < 0 ? '-' : ''
  var i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + ''
  var j = (j = i.length) > 3 ? j % 3 : 0
  return symbol + negative + (j ? i.substr(0, j) + thousand : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : '')
}
const common = {
  // 码值转换
  CodeValue(name, v) {
    // name为placode_data的name
    console.info(store.state,'store.state')
    const data_list = store.state.placode_data[name].code
    for (const data_list_v of data_list) {
      if (data_list_v.codeValue === v) {
        return data_list_v.codeName
      }
    }
  },
  // 判断字符是否为空的方法
  isEmpty: function(targe) {
    if (typeof targe === 'undefined') { return true }
    if (typeof targe === 'number') { return false }
    if (typeof targe === 'string') {
      if (targe == '') {
        return true
      } else {
        return false
      }
    }
    if (targe === null) {
      return true
    }
    if (typeof targe === 'object') {
      for (var i in targe) {
        return false
      }
      return true
    }
    return false
  },
  // 通过js的内置对象JSON来进行数组对象的深拷贝
  Copy: function(obj) {
    const _obj = JSON.stringify(obj)
    return JSON.parse(_obj)
  },
  // 清洗数据
  convertKey(arrs, list, is_parseInt) {
    const newarrs = []
    arrs.map((i) => {
      const objs = {
      }
      for (const value in list) {
        if (is_parseInt && value === 'value') {
          objs[value] = parseInt(i[list[value]])
        } else {
          objs[value] = i[list[value]]
        }
      }
      newarrs.push(objs)
    })
    return newarrs
  },
    /*
* 浮点数运算函数
* */
  // 加法
  float_add: function(arg1, arg2) {
    if (!(typeof (arg1) === 'number') && (arg1 !== Infinity) && !isNaN(arg1)) {
      return 0
    }
    if (!(typeof (arg2) === 'number') && (arg2 !== Infinity) && !isNaN(arg2)) {
      return 0
    }
    let r1, r2, m
    try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
    m = Math.pow(10, Math.max(r1, r2))
    return (arg1 * m + arg2 * m) / m
  },
  // 除法
  float_div: function(arg1, arg2) {
    if (!(typeof (arg1) === 'number') && (arg1 !== Infinity) && !isNaN(arg1)) {
      return 0
    }
    if (!(typeof (arg2) === 'number') && (arg2 !== Infinity) && !isNaN(arg2)) {
      return 0
    }
    let t1, t2, r1, r2
    try { t1 = arg1.toString().split('.')[1].length } catch (e) { t1 = 0 }
    try { t2 = arg2.toString().split('.')[1].length } catch (e) { t2 = 0 }
    r1 = Number(arg1) * Math.pow(10, Math.max(t1, t2))
    r2 = Number(arg2) * Math.pow(10, Math.max(t1, t2))
    return (r1 / r2)
  },
}
export default common
