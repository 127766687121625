<template>
    <div class="show-total">
        <div class="title">{{dataJson.title}}</div>
        <div class="money"><span>￥</span>{{dataJson.total >0?dataJson.total.formatMoney():"0.00"}}</div>
        <div class="num">
            <div class="left">{{dataJson.frequencyName}}<span>{{dataJson.frequencyVal}}</span></div>
            <div class="right">{{dataJson.numName}}<span>{{dataJson.numVal}}</span></div>
        </div>
    </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    dataJson: Object,
  },
};
</script>