const config = {
  debug: false,
  // appid:"wxb7ef9ca2685fab07",//微信appid ---测试 
  appid: "wx1ba76e3f1f467d65",//微信appid ---正式 
  // backUrl:'http://meeting.pohoocredit.com',//微信授权链接回调地址--测试
  backUrl: 'http://meeting.lzbjtjt.com',//微信授权链接回调地址--正式

  // 图片访问地址
  // imgUrl:'http://171.220.245.130:7205/sys/common/static', //测试
  imgUrl: 'http://110.185.105.129:7205/sys/common/static', //正式

  // 请求地址
  // serviceHost: 'http://192.168.0.52:7205', //本地
  // serviceHost: 'http://171.220.245.130:7205', //测试

  serviceHost: 'http://110.185.105.129:7205', //正式

  // 正式
  // serviceHost: 'http://api.lzgtgroup.com/authserver',
  apiParam: {

  },
  defaultPageSize: 10
}

export default config