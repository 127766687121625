<template>
  <div class="invited">
    <div class="invited-list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="invited-item" v-for="(item, index) in list" :key="index" @click="linkDetail(item)">
          <div class="invited-head">
            <div class="invited-name">{{ item.conferenceRoomName }}</div>
            <div class="invited-time">
              {{ item.conferenceDate }} {{ item.conferenceStartTime }}~{{
                item.conferenceEndTime
              }}
            </div>
            <div class="invited-btn" v-if="item.receiptStatus == 0">回执</div>
            <div class="invited-btn btn-sign" v-if="item.receiptStatus == 1 && item.signInStatus == 0">
              签到
            </div>
          </div>
          <CellItem :title="'会议主题'" :content="item.conferenceTheme"></CellItem>
          <CellItem :title="'会议地点'" :content="item.conferenceRoomPlace"></CellItem>
          <CellItem :title="'会议召集人'" :content="item.userName"></CellItem>
          <CellItem :title="'回执状态'" :content="item.receiptStatus == 0
            ? '未回执'
            : item.receiptStatus == 1
              ? '参会'
              : '不参会'
            "></CellItem>
          <CellItem :title="'签到状态'" :content="item.signInStatus == 0 ? '未签到' : '已签到'"></CellItem>
        </div>
      </van-list>
    </div>

    <!-- <TabberItem v-show='userType != 2' :active='active'></TabberItem> -->
  </div>
</template>

<script>
import { button, Toast, NavBar, Cell, CellGroup, List, Tabbar, TabbarItem } from "vant";
import Cookies from "js-cookie";
import config from "@/config";
import ShowTotal from "@/components/ShowTotal";
import CellItem from "@/components/CellItem";
import TabberItem from "@/components/TabberItem";
export default {
  name: "index",
  data() {
    return {
      loading: false,
      finished: false,
      current: 0,
      size: 10,
      code: this.$route.query.code,
      userType: this.$route.query.userType,

      active: 2,
      // icon: {
      //   active: require('../assets/logo.png'),
      //   inactive: 'https://img01.yzcdn.cn/vant/user-inactive.png',
      // },

      list: [],
    };
  },
  methods: {
    // 下拉加载列表
    onLoad() {
      setTimeout(() => {
        // this.queryReservationByOpenId();
      }, 1000);
    },
    // 判断是否登录
    initFn() {
      var openid = Cookies.get("openid");
      if (openid == null || openid == "" || openid == "undefined") {
        if (this.code) {
          this.getWeChatOpenId();
        } else {
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.appid}&redirect_uri=${config.backUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        }
      } else {
        this.queryConferenceUserIsBind();
      }
    },
    // 跳转详情
    linkDetail(item) {
      var json = JSON.stringify(item);
      if (item.receiptStatus == 1 && item.signInStatus == 0) {
        return this.$router.push({
          path: "/signInDetail",
          query: { detail: json },
        });
      } else {
        return this.$router.push({
          path: "/receiptDetail",
          query: { detail: json },
        });
      }
    },

    // 判断用户是否绑定
    async queryConferenceUserIsBind() {
      var openId = Cookies.get("openid");
      let params = {
        openId: openId,
      };
      await this.$post(`/h5/weChat/queryConferenceUserIsBind`, params).then(
        (res) => {
          if (res.code == "0") {
            // alert(123)
            // console.info("res.result");
            // console.info(res.result);
            if (res.result == "null" || res.result == "" || res.result == null) {
              Cookies.set("userBind", 'yes');
              this.$router.push({ path: "/binding" });
            } else {
              Cookies.remove("userBind");
              this.queryReservationByOpenId();
            }
          }
        }
      );
    },

    // 获取邀约会议
    async queryReservationByOpenId() {
      console.info('测试------------测试')
      this.current++;
      var openId = Cookies.get("openid");
      let params = {
        openId: openId,
        current: this.current,
        size: this.size,
      };
      await this.$post(`/h5/weChat/queryReservationByOpenId`, params).then(
        (res) => {
          if (res.code == "0") {
            // console.info('数据请求成功：'+this.current)
            // this.list = res.result.records
            if (this.list.length == 0) {
              this.list = res.result.records;
            } else {
              this.list = [...this.list, ...res.result.records];
            }

            // 加载状态结束
            this.loading = false;

            // 数据全部加载完成
            if (res.result.records.length < this.size) {
              this.finished = true;
            }
          }
        }
      );
    },

    // 根据code获取openId
    async getWeChatOpenId() {
      let params = {
        code: this.code,
      };
      await this.$post(`/h5/weChat/getWeChatOpenId`, params).then((res) => {
        if (res.code == "0") {
          let seconds = 60;//设置过期时间（秒）
          let expires = new Date(new Date() * 1 + seconds * 1000);

          Cookies.set("openid", res.result.openid, { expires: 7 });
          // console.info('获取了openId:'+res.result.openid)
          this.queryConferenceUserIsBind();
        }
      });
    },
  },
  created() {
    this.initFn();
  },
  mounted() { },
  components: {
    [Toast.name]: Toast,
    [button.name]: button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [NavBar.name]: NavBar,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    ShowTotal,
    CellItem,
    TabberItem
  },
};
</script>

<style lang="scss" scoped>
.invited {
  background: #f4f4f4;
  min-height: 100vh;
}

.invited-list {
  overflow: hidden;
}

.invited-item {
  margin: 12px 12px 0;
  padding: 15px 19px;
  border-radius: 7px;
  background: #fff;

  .invited-head {
    position: relative;
    border-bottom: 1px solid #dedede;
    padding-bottom: 9px;

    .invited-name {
      font-size: 18px;
      font-weight: bold;
      color: #4d4d4d;
    }

    .invited-time {
      margin-top: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #7e7e7e;
    }

    .invited-btn {
      position: absolute;
      right: 0;
      top: 3px;
      font-size: 14px;
      font-weight: 400;
      color: #fefefe;
      background: #e59c45;
      border-radius: 14px;
      // padding: 6px 13px;
      width: 70px;
      height: 28px;
      line-height: 28px;
      text-align: center;

      &.btn-sign {
        background: #526eff;
      }
    }
  }
}
</style>