import Vue from "vue";
import Vuex from "vuex";
import common from '@/utils/common'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    placode: JSON.parse(localStorage.getItem(`PLACODE`)) || '',
    placode_data: JSON.parse(localStorage.getItem(`PLACODE_DATA`)) || '',
  },
  mutations: {
    // 资料码值
    PLACODE_DATA: (state, placode_data) => {
      state.placode_data = placode_data
      // let if_flag = ''
      // const key_list = {
      //   'value': 'codeValue',
      //   'text': 'codeName'
      // }

      // if_flag = common.convertKey(placode_data.IF_FLAG.code, key_list)

      // localStorage.setItem('IF_FLAG', JSON.stringify(if_flag))
      // state.if_flag = if_flag
    },
    // 资料码值
    PLACODE: (state, placode) => {
      state.placode = placode
    },
  },
  actions: {
    // 资料码值
    SetPlacode({ commit }, data) {
      commit('PLACODE', common.Copy(data))
      localStorage.setItem('PLACODE', JSON.stringify(data))
      for (const v in data) {
        data[v].code.unshift({
          codeName: '全部',
          codeValue: ''
        })
      }
      commit('PLACODE_DATA', data)
      localStorage.setItem('PLACODE_DATA', JSON.stringify(data))
    },
  },
  modules: {},
});
