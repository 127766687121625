import Vue from "vue";
import VueRouter from "vue-router";
import cookies from 'js-cookie'
import index from "../views/index.vue";

Vue.use(VueRouter);
const isLogin = (to, from, next) => {
  // if (cookies.get("Admin-Token")) {
  next();
  // } else {
  //   next("/login");
  // }

};
const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    beforeEnter: isLogin
  },
  {
    path: "/index",//回执签到
    name: "index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/binding",//绑定页面
    name: "binding",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "binding" */ "../views/binding.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/receiptDetail",//回执详情
    name: "receiptDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "receiptDetail" */ "../views/receiptDetail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/signInDetail",//签到详情
    name: "signInDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "signInDetail" */ "../views/signIn.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/meetingDetail",//扫码=》会议详情
    name: "meetingDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "meetingDetail" */ "../views/meetingDetail.vue"),
    beforeEnter: isLogin
  },

  {
    path: "/homeIndex",//首页
    name: "homeIndex",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "homeIndex" */ "../views/home/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/reservation",//会议预约
    name: "reservation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "reservation" */ "../views/home/reservation.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/meeting",//我的会议
    name: "meeting",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "meeting" */ "../views/meeting/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/myMeetingDetail",//我的会议详情
    name: "myMeetingDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "meeting" */ "../views/meeting/detail.vue"),
    beforeEnter: isLogin
  },

  {
    path: "/message",//消息
    name: "message",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "message" */ "../views/message/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/messagedetail",//消息详情
    name: "messagedetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "message" */ "../views/message/detail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/usercenter",//我的
    name: "usercenter",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "usercenter" */ "../views/user/index.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/setphone",//修改手机号
    name: "setphone",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "setphone" */ "../views/user/setphone.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/setemail",//修改邮箱
    name: "setemail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "setemail" */ "../views/user/setemail.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/setpassword",//密码管理
    name: "setpassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "setpassword" */ "../views/user/setpassword.vue"),
    beforeEnter: isLogin
  },
  {
    path: "/setwx",//解绑微信
    name: "setwx",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "setwx" */ "../views/user/setwx.vue"),
    beforeEnter: isLogin
  },


];

const router = new VueRouter({
  // base: '/smart-ios/', 
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
